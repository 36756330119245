import React, { Suspense, lazy, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import loadable from '@loadable/component';
import ContentLoader from 'loader/ContentLoader';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';
import { Bars } from 'react-loader-spinner';


const LoadingComponent = () => (
  <div className="loader-container">
    {/* <ContentLoader /> */}
    <Bars
        height="80"
        width="80"
        color="#4fa94d"
        ariaLabel="bars-loading"
        wrapperStyle={{display: 'flex', justifyContent: 'center'}}
        wrapperClass=""
        visible={true}
      />
  </div>
);

const ChatHistoryComponent = loadable(() => import('pages/history/ChatHistory'), {
  fallback: <LoadingComponent />,
});

const WordUsageHistoryComponent = loadable(() => import('pages/history/WordUsageHistory'), {
  fallback: <LoadingComponent />,
});

const AIChat = loadable(() => import('pages/chat/index'), {
  fallback: <LoadingComponent />,
});

const DashboardComponent = loadable(() => import('pages/home/Dashboard'), {
  fallback: <LoadingComponent />,
});

const AIAudioScript = loadable(() => import('pages/audioScript/index'), {
  fallback: <LoadingComponent />,
});
const AITranscription = loadable(() => import('pages/transcription/index'), {
  fallback: <LoadingComponent />,
});
const NewTranscription = loadable(() => import('pages/transcription/NewTranscription'), {
  fallback: <LoadingComponent />,
});
const TranscriptionDetail = loadable(() => import('pages/transcription/TranscriptionDetailv0'), {
  fallback: <LoadingComponent />,
});
const EditSubtitleComponent = loadable(() => import('pages/voiceover/EditSubtitleComponent'), {
  fallback: <LoadingComponent />,
});
const DubbingComponent = loadable(() => import('pages/transcription/DubbingComponent'), {
  fallback: <LoadingComponent />,
});
const DocumentTranslation = loadable(() => import('pages/translation/DocumentTranslation'), {
  fallback: <LoadingComponent />,
});
const SettingsComponent = loadable(() => import('pages/settings/index'), {
  fallback: <LoadingComponent />,
});
const AIVoiceover = loadable(() => import('pages/voiceover/index'), {
  fallback: <LoadingComponent />,
});

const DialogEditorIndex = loadable(() => import('pages/voiceover/DialogEditorIndex'), {
  fallback: <LoadingComponent />,
});

const MyAudiosListComponent = loadable(() => import('pages/voiceover/MyAudiosList'), {
  fallback: <LoadingComponent />,
});

const MyVoiceCloning = loadable(() => import('pages/voiceover/MyVoiceCloning'), {
  fallback: <LoadingComponent />,
});

const VoiceListComponent = loadable(() => import('pages/voiceover/VoiceListComponent'), {
  fallback: <LoadingComponent />,
});

const VoiceoverUserGuideComponent = loadable(() => import('pages/voiceover/VoiceoverUserGuideComponent'), {
  fallback: <LoadingComponent />,
});

const TranscriptionUserGuideComponent = loadable(() => import('pages/transcription/TranscriptionUserGuideComponent'), {
  fallback: <LoadingComponent />,
});

const DocumentHome = loadable(() => import('pages/documents/index'), {
  fallback: <LoadingComponent />,
});

const MyNotes = loadable(() => import('pages/documents/MyNotes'), {
  fallback: <LoadingComponent />,
});
const TemplateList = loadable(() => import('pages/preset/TemplateList'), {
  fallback: <LoadingComponent />,
});
const TemplateForm = loadable(() => import('pages/preset/TemplateForm'), {
  fallback: <LoadingComponent />,
});
const AIImage = loadable(() => import('pages/aiimage/index'), {
  fallback: <LoadingComponent />,
});
const Settings = loadable(() => import('pages/settings/index'), {
  fallback: <LoadingComponent />,
});
const MyDrafts = loadable(() => import('pages/voiceover/MyDrafts'), {
  fallback: <LoadingComponent />,
});
const Plans = loadable(() => import('pages/plans/index'), {
  fallback: <LoadingComponent />,
});
const BuyAddons = loadable(() => import('pages/plans/BuyAddons'), {
  fallback: <LoadingComponent />,
});
const Addons = loadable(() => import('pages/plans/BuyAddons'), {
  fallback: <LoadingComponent />,
});
const PaymentFailureComponent = loadable(() => import('pages/plans/paymentFaliure'), {
  fallback: <LoadingComponent />,
});
const PaymentSuccessComponent = loadable(() => import('pages/plans/paymentSuccess'), {
  fallback: <LoadingComponent />,
});
const BuyCreditSuccess = loadable(() => import('pages/plans/BuyCreditSuccess'), {
  fallback: <LoadingComponent />,
});
const BuyCreditFailure = loadable(() => import('pages/plans/BuyCreditFailure'), {
  fallback: <LoadingComponent />,
});
const NotFound = loadable(() => import('pages/auth/Notfound'), {
  fallback: <LoadingComponent />,
});

const AudioShare = loadable(() => import('pages/share/AudioShare'), {
  fallback: <LoadingComponent />,
});

const AudioEmbed = loadable(() => import('pages/share/AudioEmbed'), {
  fallback: <LoadingComponent />,
});

const AudioConverter = loadable(() => import('pages/voiceover/AudioConverter'), {
  fallback: <LoadingComponent />,
});

const AudioWidgetSettings = loadable(() => import('pages/voiceover/WidgetSettings'), {
  fallback: <LoadingComponent />,
});

const FeatureRoadmap = loadable(() => import('pages/home/FeatureRoadmap'), {
  fallback: <LoadingComponent />,
});

const MainLayout = loadable(() => import('layout/MainLayout'), {
  fallback: <LoadingComponent />,
});

const SlimSliderLayout = loadable(() => import('layout/SlimSliderLayout'), {
  fallback: <LoadingComponent />,
});

import Login from './pages/auth/Login';
import SignUp from './pages/auth/SignUp';
import Reset from './pages/auth/reset';
import HelpPage from './pages/Help/HelpPage';

import ProtectedRoute from './pages/auth/ProtectedRoute';
import Auth from './Auth';
import StoreProvider from './StoreProvider';
import { LoadingOutlined } from '@ant-design/icons';
import MobileDrawerOnlyLayout from 'layout/MobileDrawerOnlyLayout';


const App = () => {
  
  return (
    <StoreProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Auth />}>
            <Route path="" element={<Login />} />
          </Route>
          <Route path="share">
              <Route
                path=":uuid"
                element={
                  <AudioShare />
                }
              />
          </Route>
          <Route path="embed">
              <Route
                path=":uuid"
                element={
                  <AudioEmbed />
                }
              />
          </Route>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/reset-password" element={<Reset />} />

          <Route path="chat-with-ai">
            <Route
              index
              element={
                <ProtectedRoute>
                  <AIChat />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":uuid"
              element={
                <ProtectedRoute>
                  <AIChat />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="dubbing-studio">
            <Route
              path=":uuid"
              element={
                <ProtectedRoute>
                  <DubbingComponent />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="transcription">
              <Route
                path=":uuid"
                element={
                  <ProtectedRoute>
                    <TranscriptionDetail />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

          <Route path="studio" element={<MobileDrawerOnlyLayout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <AIVoiceover />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":uuid"
              element={
                <ProtectedRoute>
                  <AIVoiceover />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="dialogue-studio" element={<MobileDrawerOnlyLayout />}>
            <Route
              index
              element={
                <ProtectedRoute>
                  <DialogEditorIndex dialogue={true} />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":uuid"
              element={
                <ProtectedRoute>
                  <DialogEditorIndex dialogue={true} />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="documents">
            <Route
              path="new"
              element={
                <ProtectedRoute>
                  <DocumentHome />{' '}
                </ProtectedRoute>
              }
            />
            <Route
              path=":nodeId"
              element={
                <ProtectedRoute>
                  <DocumentHome />
                </ProtectedRoute>
              }
            />
          </Route>

          
          <Route path="features-roadmap">
                <Route
                  index
                  element={
                    <FeatureRoadmap />
                  }
                />
              </Route>
          <Route path="/" element={<MainLayout />}>
            <Route path="buy-credits">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <BuyAddons />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="payment-success">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PaymentSuccessComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="payment-failure">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <PaymentFailureComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="credit-purchase-success">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <BuyCreditSuccess />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="credit-purchase-failure">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <BuyCreditFailure />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route
              index
              element={
                <ProtectedRoute>
                  <DashboardComponent />
                </ProtectedRoute>
              }
            />

            <Route path="dashboard">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <DashboardComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="help" element={<HelpPage />} />

            <Route path="templates">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TemplateList />
                  </ProtectedRoute>
                }
              />
              <Route
                path=":templateid"
                element={
                  <ProtectedRoute>
                    <TemplateForm />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="documents">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyNotes />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="transcriptions">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AITranscription />{' '}
                  </ProtectedRoute>
                }
              />
              <Route
                path="new"
                element={
                  <ProtectedRoute>
                    <NewTranscription />
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="document-translation">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <DocumentTranslation />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="settings">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Settings />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="my-drafts">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyDrafts />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="chat-history">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <ChatHistoryComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="word-usage-history">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <WordUsageHistoryComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="plan">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Plans />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="addons">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <Addons />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>

            <Route path="create-subtitle">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <EditSubtitleComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            
            <Route path="studio">
              <Route
                path='widget-settings'
                element={
                  <ProtectedRoute>
                    <AudioWidgetSettings />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="tools">
              <Route
                path='audio-converter'
                element={
                  <ProtectedRoute>
                    <AudioConverter />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="audio-files">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyAudiosListComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="voice-cloning">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <MyVoiceCloning />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="voice-library">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <VoiceListComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            <Route path="voiceover/user-guide">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <VoiceoverUserGuideComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
            {/* <Route path="ai-audio-script">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AIAudioScript />{' '}
                  </ProtectedRoute>
                }
              />
            </Route> */}
            {/* <Route path="transcription/user-guide">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <TranscriptionUserGuideComponent />{' '}
                  </ProtectedRoute>
                }
              />
            </Route> */}
            <Route path="/ai-image">
              <Route
                index
                element={
                  <ProtectedRoute>
                    <AIImage />{' '}
                  </ProtectedRoute>
                }
              />
            </Route>
          </Route>
          <Route path="settings">
            <Route
              index
              element={
                <ProtectedRoute>
                  <SettingsComponent />{' '}
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
        {false && <TawkMessengerReact propertyId={propertyId} widgetId={widgetId} />}
      </BrowserRouter>
    </StoreProvider>
  );
};
export default App;
